












import NotFound from '@/errors/views/NotFound.vue';
import {defineComponent, reactive} from '@vue/composition-api';
import {inject} from '@/container';
import {UseAssignmentWrapper} from '@/assignments/composables/UseAssignmentWrapper';
import {MARKING_TASKS} from '@/router/route-names';
import {useRoute} from '@/router/composables';

export default defineComponent({
  name: 'AssignmentWrapper',
  components: {NotFound},
  setup(props, {attrs}) {
    const {
      fetchUserData,
      initializeAssignment,
      assignmentChanged,
      assignmentExists,
      assignmentId,
      assignment,
      isNew,
      isAssignmentLoaded,
      isReady,
      routeUserId,
      user,
      assignmentExtensions,
      latestExtension,
    } = inject(UseAssignmentWrapper);

    fetchUserData();
    initializeAssignment(useRoute().name === MARKING_TASKS);

    return {
      attrs: reactive({...attrs, assignment, assignmentId, assignmentExtensions, latestExtension}),
      assignmentChanged,
      assignmentExists,
      isNew,
      isAssignmentLoaded,
      isReady,
      routeUserId,
      user,
    };
  },
});

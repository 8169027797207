import {inject, makeDependency} from '@/container';
import {Route, useRoute} from '@/router/composables';
import {Route as VueRoute} from 'vue-router';
import {AuthService, useAuthService} from '@/auth/services/authService';
import {
  LoadingFlagsService,
  LoadingFlagsServiceClass,
} from '@/loading/services/LoadingFlagsService';
import {ErrorReportingService} from '@/errors/services/error-reporting';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import User from '@/users/models/User';
import Assignment from '@/assignments/models/Assignment';
import CourseLikeAssignment from '@/assignments/models/CourseLikeAssignment';
import AssignmentExtension from '@/assignments/models/AssignmentExtension';
import {computed, ref, watch} from '@vue/composition-api';
import Role from '@/users/models/Role';
import {MARKING_TASKS} from '@/router/route-names';

export const UseAssignmentWrapper = makeDependency(() => {
  return makeAssignmentWrapper(
    inject(Route),
    useAuthService() as AuthService,
    inject(LoadingFlagsService),
    inject(ErrorReportingService)
  );
});

export function makeAssignmentWrapper(
  route: VueRoute,
  auth: AuthService,
  loadingFlags: LoadingFlagsServiceClass,
  errorReporting: ErrorReportingService
) {
  function makeNewAssignment() {
    return {
      id: null,
      name: 'New Assignment',
      overviewText: 'Overview text...',
      taskAssignments: [],
      courseLikeAssignments: [],
    };
  }

  async function fetchUserData() {
    if (routeUserId.value) {
      return loadingFlags.loadingHandler(
        LoadingFlag.AssignmentUser,
        User.api.get(routeUserId.value)
      );
    }
    return true;
  }

  function initializeAssignment(includeAssistedGradingMap: boolean = false) {
    if (!isNew.value) {
      fetchAssignmentData(includeAssistedGradingMap);
    }
  }

  function fetchAssignmentData(includeAssistedGradingMap: boolean = false) {
    if (assignmentId.value !== 'new') {
      loadingFlags
        .loadingHandler(
          LoadingFlag.AssignmentWrapperAssignment,
          Promise.all([
            Assignment.api.get(assignmentId.value, {
              includeTasks: true,
              includeAssistedGradingMap: includeAssistedGradingMap,
            }),
            CourseLikeAssignment.api.fetchForAssignment(assignmentId.value),
          ])
        )
        .then(() => (assignmentExists.value = true))
        .catch(() => (assignmentExists.value = false));

      const assignmentIdValue = assignmentId.value;
      if (user.value) {
        fetchExtensionData(assignmentIdValue);
      } else {
        fetchUserData().then(() => fetchExtensionData(assignmentIdValue));
      }
    }
  }

  function fetchExtensionData(assignmentIdValue: number) {
    AssignmentExtension.api
      .fetch({
        assignment: assignmentIdValue,
        user: user.value.id,
      })
      .catch(errorReporting.errorDialogHandler);
  }

  const assignmentChanged = () => fetchAssignmentData();

  const assignmentExists = ref(true);

  const assignmentId = computed(() =>
    route.params.assignmentId === 'new' ? 'new' : parseInt(route.params.assignmentId)
  );

  const isMarking = computed(() => useRoute().name === MARKING_TASKS);

  watch([assignmentId, isMarking], () => initializeAssignment(isMarking.value));

  const assignment = computed(() => {
    if (isNew.value) {
      return makeNewAssignment();
    } else {
      return Assignment.fullQuery({
        taskAssignments: true,
        taskContent: true,
        courseLikeAssignments: true,
        owner: true,
      }).find(assignmentId.value);
    }
  });

  const isNew = computed(() => assignmentId.value === 'new');

  const isAssignmentLoaded = computed(() =>
    loadingFlags.isLoaded(LoadingFlag.AssignmentWrapperAssignment)
  );

  const isReady = computed(() => isNew.value || (isAssignmentLoaded.value && !!assignment.value));

  const routeUserId = computed(() => (route.params.userId ? parseInt(route.params.userId) : null));
  watch(routeUserId, fetchUserData);

  const user = computed(() =>
    routeUserId.value ? User.query().find(routeUserId.value) : auth?.user
  );

  const assignmentExtensions = computed(() => {
    if (assignmentId.value !== 'new') {
      return AssignmentExtension.queryByAssignmentUser(assignmentId.value, user.value.id).get();
    } else {
      return null;
    }
  });

  const latestExtension = computed(() => assignmentExtensions.value?.[0] ?? null);

  return {
    fetchUserData,
    initializeAssignment,
    fetchAssignmentData,
    assignmentChanged,
    assignmentExists,
    assignmentId,
    assignment,
    isNew,
    isAssignmentLoaded,
    isReady,
    routeUserId,
    user,
    assignmentExtensions,
    latestExtension,
  };
}
